<template>
  <div
    class="payment-method"
    :class="{ 'is-selected': selected }"
    @click="selectItem"
  >
    <div class="radio-button"></div>
    <template>
      <div class="card-information" v-if="paymentMethodType === 'saved-card'">
        <template>
          <img
            v-if="cardData"
            class="card-icon"
            :src="cardData.icon"
            :alt="cardData.brand"
          />
          <i class="material-icons" v-else>credit_card</i>
        </template>

        <span>•••• {{ paymentMethod.card.last4 }}</span>
      </div>
      <div class="sezzle-payment" v-else-if="paymentMethodType === 'sezzle'">
        <div class="card-information">
          <img
            class="sezzle-logo"
            src="@/assets/sezzle_logo.svg"
            alt="sezzle"
          />
          <span>{{ $t("payment_information_interest_free") }}</span>
        </div>
        <div class="payment-details" v-if="selected">
          <img src="@/assets/leave_page_action.svg" alt="tab" />
          <span class="text">{{
            $t("payment_information_after_clicking")
          }}</span>
        </div>
      </div>
      <span v-else class="card-information"
        >+ {{ $t("payment_information_add_new_card") }}</span
      >
    </template>
  </div>
</template>
<script>
import { CREDIT_CARDS } from "@/lists";
import { find } from "lodash";
export default {
  data: () => ({
    selected: false,
  }),
  props: {
    changeEvent: Boolean,
    paymentMethod: null,
    paymentMethodType: String,
    default: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardData() {
      if (!this.paymentMethod) return;
      let { card } = this.paymentMethod;

      return find(CREDIT_CARDS, { brand: card.brand });
    },
  },
  watch: {
    changeEvent: {
      handler() {
        this.selected = false;
      },
    },
  },
  methods: {
    selectItem() {
      if (this.selected) return;

      this.$emit("on-item-select", {
        ...this.paymentMethod,
        paymentMethodType: this.paymentMethodType,
      });
      this.$nextTick(() => {
        this.selected = true;
      });
    },
  },
  mounted() {
    if (this.default) this.selectItem();
  },
};
</script>
<style lang="scss" scoped>
$mobile-view: 1024px;

.payment-method {
  min-height: 84px;
  display: flex;
  flex-direction: row;
  // align-items: center;
  @include cursorPointer;
  @media screen and (max-width: $mobile-view) {
    min-height: 64px;
  }
  .radio-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 22px;
    min-width: 22px;
    border: 1px solid #bbbbbb;
    border-radius: 50%;
    margin-right: 22px;
    margin-top: 31px;
    @media screen and (max-width: $mobile-view) {
      margin-top: 21px;
    }
  }
  &.is-selected {
    .radio-button::after {
      content: " ";
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background-color: #000000;
    }
  }
  .card-information {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    .card-icon {
      height: 16px;
      width: 24px;
      margin-right: 18px;
    }
    .material-icons {
      // font-size: 20px;
      margin-right: 18px;
    }
  }
  .sezzle-payment {
    display: flex;
    flex-direction: column;
    .card-information {
      height: 84px;
      @media screen and (max-width: $mobile-view) {
        height: 64px;
      }
    }
    .sezzle-logo {
      padding-bottom: 4px;
      margin-right: 12px;
    }
    .payment-details {
      padding-top: 10px;
      padding-bottom: 40px;
      display: flex;
      flex-direction: row;
      @media screen and (max-width: $mobile-view) {
        flex-direction: column;
        align-items: center;
        padding-top: 0;
        padding-bottom: 28px;
      }
      img {
        margin-right: 36px;
        @media screen and (max-width: $mobile-view) {
          width: 56px;
          height: 33px;
          margin-right: 0;
          margin-bottom: 14px;
        }
      }
      .text {
        color: #4f4f4f;
        font-size: 14px;
        line-height: 21px;
        max-width: 352px;
        @media screen and (max-width: $mobile-view) {
          font-size: 12px;
          line-height: 18px;
          max-width: 278px;
        }
      }
    }
  }
}
</style>